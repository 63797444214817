<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Plugin</h2> -->
        <img src="@/assets/images/logo 4.jpg" alt="" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to plugin-CRM! 👋
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- form -->
          <g-form @submit="login">
            <!-- email -->
            <label>{{ $t('userName') }}</label>
            <g-field
              :value.sync="userName"
              placeholder=""
              label="userName"
              id="login-userName"
              name="userName"
            >
            </g-field>
            <!-- forgot password -->
            <b-form-group>
            <label>{{ $t('password') }}</label>
              <g-field
                label="Password"
                :value.sync="password"
                name="login-password"
                :type="passwordFieldType"
                placeholder="············"
              >
              </g-field>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t('rememberMe') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button type="submit" variant="primary" block>
              {{ $t('Sign in') }}
            </b-button>
          </g-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
/* eslint-disable global-require */
// import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormCheckbox,
  // BCardText,
  BCardTitle,
  BImg,
  BButton,
} from 'bootstrap-vue';
// import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormCheckbox,
    // BCardText,
    BCardTitle,
    BImg,
    BButton,
    // VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      updatedUserName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      loginApi: 'auth/AdminLogin',
    }),
    login() {
      this.loginApi({ email: this.userName, password: this.password });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Form Submitted',
            icon: 'EditIcon',
            variant: 'error',
          },
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
